<template>
  <!-- 商标注册 -->
  <div class="trademark">
    <div class="tmTop">
      <el-carousel height="440px" interval="5000">
        <el-carousel-item>
          <img
            src="https://tms-static.cdn.bcebos.com/tms-new-portal/banner.png"
            alt=""
            class="elImg"
          />
          <div class="elText">
            <p class="et1">商标注册，开启企业品牌保护之路</p>
            <p class="et2">
              极速申报快至1分钟/流程全透明进度实时掌握/海量精选商标即买即用
            </p>
            <div class="et3">查看详情</div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img
            src="https://tms-static.cdn.bcebos.com/tms-new-portal/banner-jiaoyi.png"
            alt=""
            class="elImg"
          />
          <div class="elText">
            <p class="et1">百万标源，轻轻松松买好标</p>
            <p class="et2">
              极速申报快至1分钟/流程全透明进度实时掌握/海量精选商标即买即用
            </p>
            <div class="et3">查看详情</div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="tmSearch">
        <div class="tsBox">
          <p class="tb1">商标注册，开启企业品牌保护之路</p>
          <div class="tb2">
            <div class="t2Search">
              <select>
                <option value="1">商标名称</option>
                <option value="2">申请人名称</option>
              </select>
              <input type="text" placeholder="请输入商标名称" />
              <button>免费查询</button>
            </div>
            <div class="t2Btn">想要闲置商标</div>
          </div>
          <div class="tb3">
            <ul>
              <li class="t3Li" v-for="item in 7" :key="item">商标注册</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="tmBox1" v-for="item in 2" :key="item">
      <div class="tb1Top">
        <p class="tt1">商标注册</p>
        <p class="tt2">
          智能申请注册、专家辅助注册、至尊无忧注册等多种模式，满足不同用户需求，更专业、
          更快速、更智能
        </p>
      </div>
      <div class="tb1List">
        <ul>
          <li class="t1lLi" v-for="item in 3" :key="item">
            <div class="tl1">
              <div class="tl1P">
                <p class="tp1">智能申请注册</p>
                <p class="tp1">
                  0服务费，针对有一定商标申请经验并能自主评估风险的用户
                </p>
              </div>
            </div>
            <div class="tl2">
              <div class="tl2P">
                <div class="tps" v-for="item in 3" :key="item">
                  <img
                    src="https://tms-static.cdn.bcebos.com/tms-new-portal/iconOk.png"
                    alt=""
                    class="tpsIcon"
                  />
                  <p class="tpsp">自助办理，极速递交，性价比首选</p>
                </div>
              </div>
            </div>
            <div class="tl3">
              <div class="t31">
                <div class="t31b">限时抢购</div>
                <p class="t31p">300元</p>
              </div>
              <div class="t32">
                <div class="t32b">新用户专享270元</div>
                <div class="t32b">立即申请</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="tmBox2">
      <div class="tb2Box">
        <div class="t2bTop">
          <p class="tt1">商标延伸服务</p>
          <p class="tt2">
            专业顾问一对一服务，先评估再付款，为您提供高性价比服务方案
          </p>
        </div>
        <div class="t2bList">
          <div class="t2bLeft">
            <div class="t2l">
              <div class="t2lBox" v-for="item in 2" :key="item">
                <p class="t2l1">商标确权维护</p>
                <p class="t2l2">商标智能监控|商标异议答辩|商标转让申...</p>
              </div>
            </div>
          </div>
          <div class="t2bRight">
            <ul>
              <li class="t2rLi" v-for="item in 6" :key="item">
                <div class="trlBox">
                  <div class="trb1">
                    <img
                      src="https://tms-static.cdn.bcebos.com/tms-new-portal/license.png"
                      alt=""
                      class="t1Img"
                    />
                  </div>
                  <p class="trb2">商标智能监控</p>
                  <p class="trb3">
                    智能监测指定商标名称、申请人相关商标，展现商标中存在的潜在驳回、可异...
                  </p>
                  <p class="trb4">前100名限免</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="tmBox3">
      <div class="tb3Box">
        <div class="t3bTop">
          <p class="t31">申请流程</p>
          <p class="t32">
            商标申请很复杂？威有商标服务全流程透明，并可专家全程辅助，操作更简便，申请更简单！
          </p>
        </div>
        <div class="t3bImg">
          <img
            src="https://tms-static.cdn.bcebos.com/tms-new-portal/process.png"
            alt=""
            class="t3Bg"
          />
        </div>
      </div>
    </div>
    <div class="tmBox4">
      <div class="tb4Box">
        <div class="t4bTop">
          <p class="t41">服务优势</p>
          <p class="t42">更专业的商标服务流程，更优质的商标申请体验</p>
        </div>
        <div class="t4bList">
          <ul>
            <li class="t4lLi" v-for="item in 4" :key="item">
              <div class="t4lBox">
                <div class="t4b1">
                  <img
                    src="https://tms-static.cdn.bcebos.com/tms-new-portal/major.png"
                    alt=""
                    class="t4Icon"
                  />
                </div>
                <p class="t4b2">专业服务</p>
                <p class="t4b3">
                  资深商标顾问问题提供专业的商标检索、商标注册和商标咨询服务
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tmBox5">
      <div class="tb5Box">
        <div class="t5bTop">
          <p class="t51">使用指南</p>
          <p class="t52">不会注册商标？这里帮你从0了解商标，安心使用商标服务</p>
        </div>
        <div class="t5bList">
          <ul>
            <li class="t5Li" v-for="item in 3" :key="item">
              <div class="t5lBox">
                <div class="t5b1">
                  <img
                    src="https://tms-static.cdn.bcebos.com/tms-new-portal/product.png"
                    alt=""
                    class="t5Icon"
                  />
                  <p class="t51p">新闻公告</p>
                  <i class="iconfont icon-youbianjiantou"></i>
                </div>
                <div class="t5b2">
                  <ul>
                    <li class="t52p" v-for="item in 3" :key="item">
                      【公告】关于新用户专享270元起优惠活动调整通知
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Trademark",
};
</script>

<style lang="less" scoped>
.trademark {
  width: 100%;
  margin: 0 auto;
  margin-top: 87px;
  .tmTop {
    width: 100%;
    height: 540px;
    position: relative;
    .el-carousel {
      position: relative;
      .el-carousel__item {
        width: 100%;
        height: 440px;
        .elImg {
          width: 100%;
          height: 100%;
        }
        .elText {
          width: 45%;
          height: 250px;
          position: absolute;
          top: 100px;
          left: 150px;
          color: white;
          .et1 {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 38px;
          }
          .et2 {
            width: 100%;
            height: 18px;
            line-height: 18px;
            font-size: 16px;
            margin-top: 20px;
          }
          .et3 {
            width: 110px;
            height: 35px;
            border: 1px solid white;
            line-height: 35px;
            border-radius: 5px;
            font-size: 14px;
            text-align: center;
            margin-top: 50px;
          }
        }
      }
    }
    .tmSearch {
      width: 1280px;
      height: 150px;
      border-radius: 10px;
      box-shadow: 1px 1px 10px 1px gainsboro;
      background-color: white;
      position: absolute;
      bottom: 20px;
      left: 100px;
      z-index: 9;
      .tsBox {
        width: 95%;
        height: 90%;
        margin: 0 auto;
        margin-top: 5px;
        overflow: hidden;
        .tb1 {
          width: 100%;
          height: 18px;
          line-height: 18px;
          margin-left: 20px;
          margin-top: 10px;
        }
        .tb2 {
          height: 50px;
          margin-top: 10px;
          margin-left: 20px;
          .t2Search {
            width: 900px;
            height: 50px;
            float: left;
            box-shadow: 1px 1px 10px 1px gainsboro;
            border-radius: 5px;
            select {
              width: 15%;
              height: 50px;
              float: left;
              background-color: white;
              border: none;
              color: #2469f3;
              border-right: 1px solid gainsboro;
              outline: none;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
            input {
              width: 65%;
              height: 50px;
              float: left;
              border: none;
              outline: none;
              padding-left: 20px;
              box-sizing: border-box;
            }
            button {
              width: 20%;
              height: 50px;
              float: left;
              border: none;
              background-color: #2469f3;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              color: white;
              font-size: 14px;
            }
          }
          .t2Btn {
            width: 160px;
            height: 50px;
            border-radius: 5px;
            border: 1px solid #2469f3;
            color: #2469f3;
            text-align: center;
            line-height: 50px;
            font-size: 18px;
            float: left;
            margin-left: 30px;
          }
        }
        .tb3 {
          width: 100%;
          height: 18px;
          margin-top: 15px;
          margin-left: 10px;
          ul {
            width: 100%;
            height: 100%;
            .t3Li {
              width: 70px;
              height: 18px;
              line-height: 18px;
              border-right: 1px solid gainsboro;
              float: left;
              margin-left: 5px;
              font-size: 14px;
              color: gray;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .tmBox1 {
    width: 100%;
    height: 620px;
    margin-top: 30px;
    overflow: hidden;
    .tb1Top {
      width: 100%;
      height: 70px;
      margin-top: 20px;
      text-align: center;
      .tt1 {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 28px;
      }
      .tt2 {
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        color: gray;
      }
    }
    .tb1List {
      width: 100%;
      height: 400px;
      margin-top: 50px;
      ul {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        .t1lLi {
          width: 378px;
          height: 400px;
          background-color: white;
          box-shadow: 1px 1px 10px 1px gainsboro;
          border-radius: 10px;
          .tl1 {
            width: 100%;
            height: 126px;
            position: relative;
            background: url("https://tms-static.cdn.bcebos.com/tms-new-portal/background-grey.png")
              no-repeat right;
            overflow: hidden;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            .tl1P {
              width: 90%;
              height: 78px;
              margin: 0 auto;
              margin-top: 20px;
              .tp1 {
                width: 100%;
                height: 28px;
                line-height: 28px;
                font-size: 20px;
              }
              .tp1:nth-child(2) {
                width: 100%;
                height: 40px;
                font-size: 14px;
                color: gray;
                margin-top: 10px;
              }
            }
          }
          .tl2 {
            width: 100%;
            height: 140px;
            border-bottom: 1px solid gainsboro;
            .tl2P {
              width: 90%;
              height: 90%;
              margin: 0 auto;
              margin-top: 5%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              .tps {
                width: 100%;
                height: 35px;
                line-height: 35px;
                .tpsIcon {
                  width: 14px;
                  height: 11px;
                  float: left;
                  margin-top: 5px;
                }
                .tpsp {
                  min-width: 210px;
                  height: 20px;
                  line-height: 20px;
                  float: left;
                  margin-left: 10px;
                }
              }
            }
          }
          .tl3 {
            width: 90%;
            height: 97px;
            margin: 0 auto;
            margin-top: 10px;
            .t31 {
              width: 100%;
              height: 30px;
              .t31b {
                width: 60px;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                text-align: center;
                background-color: #ff401a;
                color: white;
                float: left;
                margin-top: 5px;
              }
              .t31p {
                float: left;
                width: 100px;
                height: 30px;
                line-height: 30px;
                font-size: 24px;
                color: #ff401a;
                margin-left: 20px;
              }
            }
            .t32 {
              width: 100%;
              height: 45px;
              margin-top: 10px;
              .t32b {
                width: 160px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                border-radius: 5px;
                background: #ff401a;
                color: white;
                float: left;
              }
              .t32b:nth-child(2) {
                margin-left: 10px;
                border: 1px solid #ff401a;
                color: #ff401a;
                background-color: white;
              }
            }
          }
        }
        .t1lLi:hover {
          box-shadow: 1px 1px 10px 1px gray;
        }
      }
    }
  }
  .tmBox2 {
    width: 100%;
    height: 660px;
    .tb2Box {
      width: 1140px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .t2bTop {
        width: 100%;
        height: 70px;
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
        .tt1 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
        }
        .tt2 {
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: gray;
          margin-top: 10px;
        }
      }
      .t2bList {
        width: 100%;
        height: 440px;
        margin-top: 50px;
        border-radius: 10px;
        box-shadow: 1px 1px 10px 1px gainsboro;
        .t2bLeft {
          width: 278px;
          height: 100%;
          background: url(https://tms-static.cdn.bcebos.com/tms-new-portal/background3.png)
            no-repeat center center;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          float: left;
          .t2l {
            width: 100%;
            height: 94px;
            .t2lBox {
              width: 90%;
              height: 90%;
              margin: 0 auto;
              margin-top: 5%;
              color: white;
              .t2l1 {
                width: 100%;
                height: 25px;
                line-height: 25px;
                font-size: 18px;
              }
              .t2l2 {
                width: 100%;
                height: 18px;
                line-height: 18px;
                font-size: 12px;
              }
            }
          }
        }
        .t2bRight {
          width: 860px;
          height: 100%;
          float: left;
          ul {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .t2rLi {
              width: 33%;
              height: 49%;
              background-color: white;
              .trlBox {
                width: 90%;
                height: 90%;
                margin: 0 auto;
                margin-top: 5%;
                .trb1 {
                  width: 100%;
                  height: 34px;
                  .t1Img {
                    width: 34px;
                    height: 34px;
                  }
                }
                .trb2 {
                  width: 100%;
                  height: 25px;
                  line-height: 25px;
                  font-size: 18px;
                  margin-top: 10px;
                }
                .trb3 {
                  width: 100%;
                  height: 40px;
                  font-size: 14px;
                  color: gray;
                  margin-top: 10px;
                }
                .trb4 {
                  width: 100%;
                  height: 33px;
                  line-height: 33px;
                  font-size: 24px;
                  color: #eb1719;
                  margin-top: 30px;
                }
              }
            }
            .t2rLi:hover {
              box-shadow: 1px 1px 10px 1px gray;
            }
          }
        }
      }
    }
  }
  .tmBox3 {
    width: 100%;
    height: 500px;
    .tb3Box {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .t3bTop {
        width: 100%;
        height: 70px;
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
        .t31 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
        }
        .t32 {
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: gray;
          margin-top: 10px;
        }
      }
      .t3bImg {
        width: 100%;
        height: 287px;
        margin-top: 30px;
        .t3Bg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .tmBox4 {
    width: 100%;
    height: 415px;
    .tb4Box {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .t4bTop {
        width: 100%;
        height: 70px;
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
        .t41 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
        }
        .t42 {
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: gray;
          margin-top: 10px;
        }
      }
      .t4bList {
        width: 100%;
        height: 198px;
        margin-top: 30px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          .t4lLi {
            width: 280px;
            height: 198px;
            border-radius: 10px;
            background-color: white;
            box-shadow: 1px 1px 10px 1px gainsboro;
            .t4lBox {
              width: 90%;
              height: 90%;
              margin: 0 auto;
              margin-top: 5%;
              .t4b1 {
                width: 100%;
                height: 48px;
                .t4Icon {
                  width: 48px;
                  height: 48px;
                  display: block;
                  margin: 0 auto;
                }
              }

              .t4b2 {
                width: 100%;
                height: 25px;
                line-height: 25px;
                text-align: center;
                font-size: 18px;
                margin-top: 20px;
              }
              .t4b3 {
                width: 100%;
                height: 40px;
                font-size: 14px;
                color: gray;
                margin-top: 10px;
              }
            }
          }
          .t4lLi:hover {
            box-shadow: 1px 1px 10px 1px gray;
          }
        }
      }
    }
  }
  .tmBox5 {
    width: 100%;
    height: 443px;
    .tb5Box {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .t5bTop {
        width: 100%;
        height: 70px;
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
        .t51 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
        }
        .t52 {
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: gray;
          margin-top: 10px;
        }
      }
      .t5bList {
        width: 100%;
        height: 226px;
        margin-top: 30px;
        box-shadow: 1px 1px 10px 1px gainsboro;
        border-radius: 10px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          .t5Li {
            width: 33%;
            height: 100%;
            border-right: 1px solid gainsboro;
            .t5lBox {
              width: 90%;
              height: 90%;
              margin: 0 auto;
              margin-top: 3%;
              .t5b1 {
                width: 100%;
                height: 35px;
                margin-top: 20px;
                .t5Icon {
                  width: 35px;
                  height: 35px;
                  float: left;
                }
                .t51p {
                  width: 80px;
                  height: 35px;
                  line-height: 35px;
                  float: left;
                  margin-left: 10px;
                }
                .iconfont {
                  margin-top: 10px;
                  float: left;
                }
              }
              .t5b2 {
                width: 100%;
                height: 100px;
                margin-top: 20px;
                ul {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: space-around;
                  flex-direction: column;
                  .t52p {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 14px;
                    color: gray;
                  }
                  .t52p:hover {
                    color: #007aff;
                  }
                }
              }
            }
          }
          .t5Li:nth-child(3) {
            border: none;
          }
        }
      }
    }
  }
}
</style>
